import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import 'boxicons/css/boxicons.min.css'

import "./src/scss/style.scss";
import "./src/scss/theme.scss"

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Ldxi-AkAAAAAP-IupFyCzm5X208GAsS9h06bIrT">
      {element}
    </GoogleReCaptchaProvider>
  )
}
